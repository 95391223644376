<!-- Start Navbar Area -->
<div class="{{navClass}}">
    <div class="kirin-nav">
        <div class="{{navContainer}}">
            <nav class="navbar navbar-expand-md navbar-light">
                <a class="navbar-brand" routerLink="/">
                    <img src="{{logo}}" class="main-logo" alt="logo">
                    <img src="assets/img/black-logo.png" class="optional-logo" alt="logo">
                </a>

                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>

                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav"> 
                        <li class="nav-item" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
                            <a routerLink="chatbot"   id="linkInicio" class="nav-link" [ngClass]="{active: isActive('inicio')}"  class="nav-link" style="cursor: pointer;"   routerLinkActive="active">Chatbot</a>
                        </li>
                        <li class="nav-item" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
                            <a  routerLink="chat-history"  id="linkHistorial" class="nav-link" [ngClass]="{active: isActive('historial')}" style="cursor: pointer;">Historial de Conversación</a>
                        </li>
                        <li class="nav-item" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
                            <a  routerLink="mood-chart-patient"  id="linkAnimico" class="nav-link" [ngClass]="{active: isActive('ganimico')}" style="cursor: pointer;">Gráfico Anímico</a>
                        </li>
                        <!-- <li class="nav-item dropdown pages-dropdown">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle" data-bs-toggle="dropdown">
                                Home <i class="fas fa-chevron-down"></i>
                            </a>
                            <ul class="dropdown-menu">
                                <li class="nav-item" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
                                    <a
                                        routerLink="/"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}"
                                        class="nav-link"
                                    >
                                        Home One
                                    </a>
                                    <a
                                        routerLink="/machine-learning"
                                        routerLinkActive="active"
                                        class="nav-link"
                                    >
                                        Home Two
                                    </a>

                                    <a
                                        routerLink="/machine-learning-2"
                                        routerLinkActive="active"
                                        class="nav-link"
                                    >
                                        Home Three
                                    </a>
                                </li>
                            </ul>
                        </li>

                        <li class="nav-item" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
                            <a routerLink="/about" routerLinkActive="active" class="nav-link">About Us</a>
                        </li>

                        <li class="nav-item dropdown pages-dropdown">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle" data-bs-toggle="dropdown">
                                Pages <i class="fas fa-chevron-down"></i>
                            </a>
                            <ul class="dropdown-menu">
                                <li class="nav-item" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
                                    <a routerLink="/about" routerLinkActive="active" class="nav-link">About Us</a>
                                </li>

                                <li class="nav-item" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
                                    <a routerLink="/team" routerLinkActive="active" class="nav-link">Team</a>
                                </li>

                                <li class="nav-item" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
                                    <a routerLink="/pricing" routerLinkActive="active" class="nav-link">Pricing</a>
                                </li>

                                <li class="nav-item dropdown-submenu">
                                    <a href="javascript:void(0)" class="nav-link">
                                        Services <i class="fas fa-chevron-down"></i>
                                    </a>
                                    <ul class="dropdown-menu">
                                        <li class="nav-item" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
                                            <a routerLink="/services" routerLinkActive="active" class="nav-link">Services</a>
                                        </li>

                                        <li class="nav-item" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
                                            <a routerLink="/service-details" routerLinkActive="active" class="nav-link">Service Details</a>
                                        </li>
                                    </ul>
                                </li>

                                <li class="nav-item dropdown-submenu">
                                    <a href="javascript:void(0)" class="nav-link">
                                        Blog <i class="fas fa-chevron-down"></i>
                                    </a>
                                    <ul class="dropdown-menu">
                                        <li class="nav-item" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
                                            <a routerLink="/blog" routerLinkActive="active" class="nav-link">Blog</a>
                                        </li>

                                        <li class="nav-item" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
                                            <a routerLink="/blog-details" routerLinkActive="active" class="nav-link">Blog Details</a>
                                        </li>
                                    </ul>
                                </li>

                                <li class="nav-item" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
                                    <a routerLink="/partner" routerLinkActive="active" class="nav-link">Partner</a>
                                </li>

                                <li class="nav-item" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
                                    <a routerLink="/not-found" routerLinkActive="active" class="nav-link">404 Error</a>
                                </li>

                                <li class="nav-item" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
                                    <a routerLink="/coming-soon" routerLinkActive="active" class="nav-link">Coming Soon</a>
                                </li>

                                <li class="nav-item" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
                                    <a routerLink="/faq" routerLinkActive="active" class="nav-link">FAQ</a>
                                </li>
                            </ul>
                        </li>

                        <li class="nav-item dropdown">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle" data-bs-toggle="dropdown">
                                Services <i class="fas fa-chevron-down"></i>
                            </a>
                            <ul class="dropdown-menu">
                                <li class="nav-item" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
                                    <a routerLink="/services" routerLinkActive="active" class="nav-link">Services</a>
                                </li>

                                <li class="nav-item" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
                                    <a routerLink="/service-details" routerLinkActive="active" class="nav-link">Services Details</a>
                                </li>
                            </ul>
                        </li>

                        <li class="nav-item dropdown">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle" data-bs-toggle="dropdown">
                                Projects <i class="fas fa-chevron-down"></i>
                            </a>
                            <ul class="dropdown-menu">
                                <li class="nav-item" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
                                    <a routerLink="/projects" routerLinkActive="active" class="nav-link">Projects</a>
                                </li>

                                <li class="nav-item" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
                                    <a routerLink="/project-details" routerLinkActive="active" class="nav-link">Project
                                        Details</a>
                                </li>
                            </ul>
                        </li>

                        <li class="nav-item dropdown">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle" data-bs-toggle="dropdown">
                                Blog <i class="fas fa-chevron-down"></i>
                            </a>
                            <ul class="dropdown-menu">
                                <li class="nav-item" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
                                    <a routerLink="/blog" routerLinkActive="active" class="nav-link">Blog</a>
                                </li>

                                <li class="nav-item" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
                                    <a routerLink="/blog-details" routerLinkActive="active" class="nav-link">Blog Details</a>
                                </li>
                            </ul>
                        </li>

                        <li class="nav-item" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
                            <a routerLink="/pricing" routerLinkActive="active" class="nav-link">Pricing</a>
                        </li>

                        <li class="nav-item" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
                            <a routerLink="/contact" routerLinkActive="active" class="nav-link">Contact</a>
                        </li> -->
                    </ul>

                    <div class="others-options">
                        <!-- <div class="option-item">
                            <i class="search-btn flaticon-search"></i>
                            <i class="close-btn fas fa-times"></i>
                            <div class="search-overlay search-popup">
                                <div class='search-box'>
                                    <form class="search-form">
                                        <input class="search-input" name="search" placeholder="Search" type="text">
                                        <button class="search-button" type="submit"><i class="fas fa-search"></i></button>
                                    </form>
                                </div>
                            </div>
                        </div> -->

                        <a  class="btn btn-primary" (click)="logOut()"><i class="bi bi-person-fill"></i> Bienvenido {{usuario}}, Cerrar Sesion</a>
                    </div>
                </div>
            </nav>
        </div>
    </div>
</div>
<!-- End Navbar Area -->
