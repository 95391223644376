import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminComponent } from './layout/admin/admin.component';
import { AuthComponent } from './layout/auth/auth.component';
import { InicioComponent } from './layout/inicio/inicio.component';

const routes: Routes = [
   {
    path:'administration',
    component:AdminComponent,
    children:[
      {
        path:'',
        loadChildren: () => import('./administration/administration.module').then(m => m.AdministrationModule)
      }
    ]
   },
   {
    path:'patients',
    component:InicioComponent,
    children:[
      {
        path:'',
        loadChildren: () => import('./patients/patients.module').then(m => m.PatientsModule)
      } 
    ]
   },
   {
    path: 'auth',
    component: AuthComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./authentication/auth.module').then(m => m.AuthModule)
      }
    ]
  },{
      path:'**',
      redirectTo: 'auth/signin',
      pathMatch: 'full'
    },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy', anchorScrolling: 'enabled',useHash: false, scrollPositionRestoration: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
