import { Component, Inject, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { DOCUMENT, Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { element } from 'protractor';
import { AuthService } from '../../services/auth.service';


@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    providers: [
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})
export class HeaderComponent implements OnInit {

    location: any;
    navClass: any;
    logo: any;
    navContainer: any;
    linkActivo:string;
    activo:string;
    isClickedPrincipalOption:boolean = true;
    buttonActive:string = '';

    //mail
    public email : string;
    public name : string;
    public message : string;
    public endpoint : string;
    public usuario: string;

    constructor(
        private router: Router,
        location: Location,
        private auth:AuthService
    ) {
        this.navClass = 'navbar-area navbar-style-four';
        this.navContainer = 'container';
        this.logo = 'assets/img/black-logo.png';
        this.usuario = this.auth.readAccess().nombre;
        
        // this.router.events
        //     .subscribe((event) => {
        //         if ( event instanceof NavigationEnd ) {
        //             this.location = this.router.url;
        //             if (this.location == '/'){
        //                 this.navClass = 'navbar-area';
        //                 this.navContainer = 'container-fluid';
        //                 this.logo = 'assets/img/white-logo.png';
        //             } else if (this.location == '/machine-learning'){
        //                 this.navClass = 'navbar-area navbar-style-three';
        //                 this.navContainer = 'container';
        //                 this.logo = 'assets/img/black-logo.png';
        //             } else if (this.location == '/machine-learning-2') {
        //                 this.navClass = 'navbar-area navbar-style-four';
        //                 this.navContainer = 'container-fluid';
        //                 this.logo = 'assets/img/black-logo.png';
        //             } else {
        //                 this.navClass = 'navbar-area navbar-style-two';
        //                 this.navContainer = 'container';
        //                 this.logo = 'assets/img/white-logo.png';
        //             }
        //         }
        //     });
    }

    ngOnInit() {  
    }

    onClick(link:string,$event){
        this.linkActivo = link;
        let x = document.querySelector(`#${link}`);
  
        
        if (x){
            x.scrollIntoView();
        }

        
    }

    isActive(item) {
        this.buttonActive = item;
        return this.linkActivo === item;
    };

    isClickedOption(){
        this.isClickedPrincipalOption = true;
        return this.isClickedPrincipalOption;
    }

    logOut(){
        this.auth.logOut();
    }

}
