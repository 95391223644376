import { HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpParams, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor{
  
  constructor(private auth:AuthService) { 
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
  
    let ruta = new URL(req.url);    
     if(!(ruta.pathname.toLowerCase() === "/api/login")){
      
        let token = this.auth.readAccess().token;
      
        const headers = new HttpHeaders(
          {
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': 'Bearer '+token
          },
        );
        
        const reqClone = req.clone({
          headers
        });

        return next.handle(reqClone).pipe(
          catchError(this.handlerError)
        );

    }else{
        return next.handle(req);
    }
      
  }

  handlerError(error: HttpErrorResponse){
    return throwError('Oops !!!, ha ocurrido un error, la alerta ya ha sido enviada al departamento de informática.');
  } 
}
