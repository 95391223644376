import {Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-header-patient',
  templateUrl: './header-patient.component.html',
  styleUrls: ['./header-patient.component.scss'],
  providers: [
    Location, {
        provide: LocationStrategy,
        useClass: PathLocationStrategy
    }]
})
export class HeaderPatientComponent implements OnInit {

  location: any;
  navClass: any;
  logo: any;
  navContainer: any;
  linkActivo:string;
  activo:string;
  isClickedPrincipalOption:boolean = true;
  buttonActive:string = '';

  //mail
  public email : string;
  public name : string;
  public message : string;
  public endpoint : string;
  public usuario: string;

  constructor(
      private router: Router,
      location: Location,
      private auth:AuthService
  ) {
      this.navClass = 'navbar-area navbar-style-four';
      this.navContainer = 'container';
      this.logo = 'assets/img/black-logo.png';
      this.usuario = this.auth.readAccess().nombre;
  }
  

  ngOnInit() {  
  }

  onClick(link:string,$event){
      this.linkActivo = link;
      let x = document.querySelector(`#${link}`);

      
      if (x){
          x.scrollIntoView();
      }

      
  }

  isActive(item) {
      this.buttonActive = item;
      return this.linkActivo === item;
  };

  isClickedOption(){
      this.isClickedPrincipalOption = true;
      return this.isClickedPrincipalOption;
  }

  logOut(){
      this.auth.logOut();
  }
}
