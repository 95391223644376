<!-- Start Footer Area -->
<footer class="footer-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <div class="logo">
                        <a routerLink="/">
                            <img src="assets/img/white-logo.png" alt="image">
                        </a>
                        <p>Xoon Psico Bot, Todos los derechos reservados.</p>
                    </div>
                    <ul class="social">
                        <li><a><i class="flaticon-facebook-letter-logo"></i></a></li>
                        <li><a><i class="flaticon-twitter"></i></a></li>
                        <li><a><i class="flaticon-instagram-logo"></i></a></li>
                        <li><a><i class="flaticon-youtube-play-button"></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Acceso Rápido</h3>

                    <ul class="footer-services-list">
                        <li><a (click)="onClick('inicio')"   style="cursor: pointer;">Inicio</a></li>
                        <li><a (click)="onClick('nosotros')" style="cursor: pointer;">Nosotros</a></li>
                        <li><a (click)="onClick('servicios')"style="cursor: pointer;">Servicios</a></li>
                        <li><a (click)="onClick('contacto')" style="cursor: pointer;">Contacto</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Asociados</h3>

                    <ul class="quick-links-list">
                        <li><a href="https://www.gob.cl/" target="_blank">Gobierno de Chile</a></li>
                        <li><a href="https://www.munistgo.cl/" target="_blank">Municipalidad de Santiago</a></li>
                        <li><a href="https://www.adsclick.cl/" target="_blank">AdsClick</a></li>
                        <li><a href="https://www.sercotec.cl/" target="_blank">Sercotec</a></li>
                        <li><a href="https://www.uc.cl/" target="_blank">Universidad Católica</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Contacto Rápido</h3>

                    <ul class="footer-contact-list">
                        <li><span>Email:</span> <a href="mailto:contacto@xoonpsicobot.cl">contacto@xoonpsicobot.cl</a></li>
                        <li><span>Teléfono:</span> <a href="tel:+56992307340">+56992307340</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="copyright-area">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <p>&copy; 2022 <a routerLink="/">Xoon Psico Bot</a>. Todos Los Derechos Reservados.</p>
                </div>

                <div class="col-lg-6 col-md-6 col-sm-6">
                    <ul>
                        <li><a href="https://www.adsclick.cl/" target="_blank">Power By adsclick.cl</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="circle-map"><img src="assets/img/circle-map.png" alt="image"></div>

    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</footer>
<!-- End Footer Area -->

<!-- <div class="go-top"><i class="fas fa-arrow-up"></i><i class="fas fa-arrow-up"></i></div> -->
